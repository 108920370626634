const messages = {
  'p.login.error': '登录失败，请重新登陆',
  'p.login.welcome': '欢迎使用',
  'p.login.employee': 'ABB 员工',
  'p.login.employeetip': '使用ABB邮箱登录',
  'p.login.customer': 'ABB 客户',
  'p.login.customertip': '查询我的订单',
  'p.errorpage.403': '访问受限',
  'p.errorpage.404': '页面不存在',
  'p.errorpage.home': '返回首页',
  'p.role.name': '角色',
  'p.role.plural': '角色',
  'p.user.name': '用户',
  'p.user.plural': '用户',
  'p.user.f.email': '邮箱',
  'p.user.f.name': '姓名',
  'p.user.f.roles': '角色',
  'p.user.s.kw': '姓名，邮箱...',
  'p.user.s.roleph': '所有角色',
  'p.user.s.allDepartment': '所有部门',
  'p.orderpoint.pdpstart': 'PDP生产开始',
  'p.orderpoint.pdpfinish': 'PDP生产完成',
  'p.orderpoint.pdpbilling': 'PDP成品报关',
  'p.orderpoint.blwarehouse': 'BL成品入库',
  'p.orderpoint.bldeparture': 'BL成品运输(出发)',
  'p.orderpoint.blarrival': 'BL成品运输(到达)',
  'p.contact.pointph': '所有节点',
  'p.contact.emailph': '邮箱...',
  'p.contact.f.ContactPoint': '订单节点',
  'p.contact.f.PlantCode': '工厂',
  'p.contact.f.CompanyCode': '公司',
  'p.contact.f.PhaseOwnerEmail': '节点联系人',
  'p.contact.f.PhaseOwnerName': '联系人名称',
  'p.contact.f.Escalation1': '1st Escalation Owner',
  'p.so.s.customerph': '客户名称...',
  'p.so.s.projectph': '项目编号...',
  'p.so.s.robotph': '机器人型号...',
  'p.so.noorder': '没有订单',
  'p.so.nobtorder': '没有BT订单',
  'p.so.selectorder': '请选择订单',
  'p.so.sotitle': '合同详细数据',
  'p.so.dntitle': '物流信息',
  'p.so.createdate': '创建日期',
  'p.so.releasedate': '发布日期',
  'p.so.sales': '销售',
  'p.so.pm': '项目经理',
  'p.so.plandeparture': '计划出发',
  'p.so.actualdeparture': '实际出发',
  'p.so.planarrival': '计划到达',
  'p.so.actualarrival': '实际到达',
  'p.so.question': '提问',
  'p.so.f.RobotType': '机器人型号',
  'p.so.f.Quantity': '数量',
  'p.so.f.SalesUnit': '单位',
  'p.dn.tobedelivered': '待发货',
  'p.dn.ondelivery': '运输中',
  'p.dn.delivered': '已签收',
  'p.dn.plandeparture': '计划出发',
  'p.dn.actualdeparture': '实际出发',
  'p.dn.planarrival': '计划到达',
  'p.dn.actualarrival': '实际到达',
  'p.dn.ActualGIDate': 'SAP发货时间',
  'p.dn.tip': '系统仅显示6个月内的物流信息',
  'p.dn.shipmentno': '物流单号',
  'p.bt.notstart': '未开始',
  'p.bt.inprod': '生产中',
  'p.bt.delivered': '已交付',
  'p.bt.ontime': 'On Time',
  'p.bt.timegap': 'Time Gap',
  'p.bt.delay': 'Delay',
  'p.bt.plandate': '计划日期',
  'p.bt.actualdate': '实际日期',
  'p.commun.f.ProjectNumber': '项目',
  'p.commun.f.QuestionType': '订单节点',
  'p.commun.f.dnorder': '相关订单',
  'p.commun.f.RobotType': '机器人型号',
  'p.commun.f.PlannedDate': '计划日期',
  'p.commun.f.ActualDate': '实际日期',
  'p.commun.f.PhaseOwner': '联系人',
  'p.commun.f.QuestionContent': '问题',
  'p.commun.f.RequestUserName': '提问人',
  'p.commun.f.RequestOn': '提问时间',
  'p.commun.f.ClosedUserName': '关闭人',
  'p.commun.f.ClosedOn': '关闭时间',
  'p.commun.f.Answered': '答复状态',
  'p.commun.dnhelp': '查询物流相关问题时请输入项目编号',
  'p.commun.btlabel': 'BT订单',
  'p.commun.dnlabel': '物流',
  'p.commun.pendinglabel': '处理中',
  'p.commun.closedlabel': '已关闭',
  'p.commun.questiontitle': '问题',
  'p.commun.answertitle': '答复',
  'p.commun.addaction': 'Add Action',
  'p.commun.closed': '已关闭',
  'p.commun.closecfm': 'Are you sure to close this action?',
  'p.answer.f.DelayReason': 'Delay Reason',
  'p.answer.f.Description': 'Description',
  'p.answer.f.UpdatePlanDate': 'Update Plan Date',
  'p.answer.f.Actions': 'Actions',
  'p.answer.f.ActionContent': 'Action',
  'p.answer.f.ActionOwner': 'Owner',
  'p.answer.f.TargetDate': 'Target Date',
  'p.answer.f.ClosedTime': 'Closed On',
  'p.customerorder.pagetitle': '订单查询',
  'p.customerorder.s.customer': '客户代码',
  'p.customerorder.s.project': '项目编号',
  'p.customerorder.searchtip': '请输入客户代码和项目编号进行查询',
  'p.customerorder.noorder': '找不到订单',
  'p.actionworkflowlist.pagetitle': '行动及跟踪',
  'p.actionworkflowlist.addissue': '添加 Issue',
  'p.actionworkflowlist.mytask': '我的待办',
  'p.actionworkflowlist.myissue': '我的Issue',
  'p.actionworkflowlist.allissue': '全部',
  'p.actionworkflowlist.mytask.pannel.issuetitle': '我的待办Issue',
  'p.actionworkflowlist.mytask.pannel.actiontitle': '我的待办Action',
  'p.actionworkflowlist.issue.columns.issuecreatedby': '发起人',
  'p.actionworkflowlist.issue.columns.issuecreatedtime': '发起时间',
  'p.actionworkflowlist.issue.columns.issuecontent': 'Issue内容',
  'p.actionworkflowlist.issue.columns.issueowner': 'Issue Owner',
  'p.actionworkflowlist.issue.columns.isrepeat': '重复发生',
  'p.actionworkflowlist.issue.columns.priority': '优先级',
  'p.actionworkflowlist.issue.columns.issuestatus': 'Issue状态',
  'p.actionworkflowlist.issue.columns.istiermeeting': '是否Tier Meeting',
  'p.actionworkflowlist.issue.columns.issuechanneltype': '问题处理渠道',
  'p.actionworkflowlist.issue.columns.actioncontent': '应对措施',
  'p.actionworkflowlist.issue.columns.actoinowner': 'Action Owner',
  'p.actionworkflowlist.issue.columns.actoinstatus': 'Action状态',
  'p.actionworkflowlist.issue.columns.actoinduedate': 'Due Date',
  'p.actionworkflowlist.addissue.attachment.msg': '您未上传附件，是否确认提交?',
  'p.actionworkflowlist.addissue.addaction.msg': '请添加Actions,谢谢。',
  'p.actionworkflowlist.issue.reject.msg': '请输入拒绝原因！',
  'p.actionworkflowlist.issue.transfer.reject.msg':
    '拒绝后，您将不需要复责跟踪Issue及Action，是否确认拒绝?',
  'p.actionworkflowlist.issue.transfer.accept.msg':
    '接受后您将成为新的Issue Owner，将不可退回，是否确定接受?',
  'p.actionworkflowlist.issue.transfer.withdraw.msg':
    '撤回后，Issue将保留原来的Issue Owner,是否确定撤回?',
  'p.actionworkflowlist.issue.transfer.msg': '是否确定转移?',
  'p.actionworkflowlist.issueform.title': '行动及跟踪',
  'p.actionworkflowlist.issueform.issuepannel.title': 'Issue 信息',
  'p.actionworkflowlist.issue.columns.beforeissueowner': '原Issue Owner',
  'p.actionworkflowlist.issueform.attachment': 'Issue附件',
  'p.actionworkflowlist.issueform.addaction.button': '新增措施',
  'p.actionworkflowlist.issueform.obsolete.button': '作废',
  'p.actionworkflowlist.issueform.issuetransferpannel.title': 'Issue移交记录',
  'p.actionworkflowlist.issueform.issuetransferpannel.comments': '移交Comments',
  'p.actionworkflowlist.issueform.issuetransferpannel.comments.placeholder':
    '请输入移交Comments',
  'p.actionworkflowlist.issuetransferform.title': 'Issue转移',
  'p.actionworkflowlist.action.columns.createdby': '创建人',
  'p.actionworkflowlist.action.columns.createdtime': '创建时间',
  'p.actionworkflowlist.action.columns.beforeduedate': '原Due Date',
  'p.actionworkflowlist.actionform.actionrecordpannel.title':
    '应对措施跟踪记录',
  'p.actionworkflowlist.actionform.obsolete.title': '作废原因',
  'p.actionworkflowlist.actiondetail.fallback.msg': '是否确定回退?',
  'p.actionworkflowlist.actiondetail.actionpanel.title': '应对措施信息',
  'p.actionworkflowlist.actiondetail.actionrecordpanel.title':
    '应对措施跟踪记录',
  'p.actionworkflowlist.actiondetail.actionfeedback.title': '应对措施执行反馈',
  'p.actionworkflowlist.actiondetail.attachment': '反馈附件',
  'p.actionworkflowlist.actiondetail.iscompleted': '是否已完成',
  'p.actionworkflowlist.issueform.placeholder.issucontent': '请输入Issue内容',
  'p.actionworkflowlist.actionform.placeholder.actioncontent':
    '请输入Action内容',
  'p.actionworkflowlist.actionform.placeholder.actionowner':
    '请输入Action Owner',
  'p.actionworkflowlist.actionform.placeholder.duedate': '请选择Due Date',
  'p.actionworkflowlist.actionform.placeholder.obsolete': '请输入作废原因',
  'p.actionworkflowlist.actiondetail.placeholder.content': '措施反馈',
  'p.actionworkflowlist.actiondetail.placeholder.content.res': '结果反馈',
  'p.actionworkflowlist.actionform.placeholder.obsoleteContents':
    '请输入作废原因',
  'p.actionworkflowlist.issueform.placeholder.tierMeetting':
    '请选择tierMeetting',
  'p.actionworkflowlist.actionlistworkflow.allisue.button.exptable': '全部展开',
  'p.actionworkflowlist.actionlistworkflow.allisue.button.fold': '全部折叠',
  'p.actionworkflowlist.actionlistworkflow.actiondetail.button.action': '执行',
  'p.actionworkflowlist.issue.cancel.msg': '您还未保存的数据，是否确认取消?',
  'p.actionworkflowlist.issue.columns.businessType': '问题来源',
  'p.actionworkflowlist.issue.columns.workflow_issue': '行动及跟踪',
  'p.actionworkflowlist.issue.columns.change_issue': '变更管理',
  'p.actionworkflowlist.issue.columns.inspection_task': '检查任务',
  'p.actionworkflowlist.issue.columns.tpm_defect_and_improvement':
    '微缺陷/焦点改善记录表',
  'p.actionworkflowlist.table.pageinfomation': '共{value}条数据',
  'p.actionworkflowlist.issue.columns.actionOverduty': '过期负责人',
  'p.actionworkflowlist.issue.columns.overduDay': '过期天数',
  'p.actionworkflowlist.actiondetail.placeholder.content.msg': '请输入反馈措施',

  'p.downloadDrawer.title': '任务列表',
  'p.downloadDrawer.btn': '下载任务',
  'p.downloadDrawer.table.clear': '请确认是否清理任务列表',

  'p.downloadDrawer.table.fileName': '文件名',
  'p.downloadDrawer.table.status': '处理状态',
  'p.downloadDrawer.table.status.loading': '处理中',
  'p.downloadDrawer.table.status.success': '成功',
  'p.downloadDrawer.table.status.failure': '失败',
  'p.downloadDrawer.table.status.queue': '队列中',
  'p.downloadDrawer.table.download': '下载数据',
  'p.downloadDrawer.table.dataTooBig': '数据量太大',
  'p.downloadDrawer.table.isDownload': '是否已下载',
  'p.downloadDrawer.fileToBig': '上传文件不可大于10M，请检查附件大小。',
  'p.downloadDrawer.fileToMore': '最多只能上传5个附件，请检查上传附件！',
  'p.downloadDrawer.clear': '一键清理',
  'p.downloadDrawer.retry': '重试',

  'p.megafactory.dashboardtitle': 'ABB MEGA Factory Dashboard',
  'p.megafactory.pagetitle': 'MEGA Factory Dashboard',
  'p.megafactory.viewdashboard': 'Dashboard',
  'p.megafactory.setting': '配置',
  'p.megafactory.tier4home': 'Tier 4 首页',
  'p.megafactory.andonstatus': 'Andon状态',
  'p.tier4home.pagetitle': '配置 - Tier 4 首页',
  'p.tier4home.covermap': '封面图',
  'p.tier4home.covertips': '按下鼠标左键拖拽选择区域，区域中心为图标位置',
  'p.tier4home.insidemap': '工厂内部',
  'p.tier4home.maptips':
    '按下鼠标左键拖拽选择区域，区域中心为图标位置；双击区域可设置区域对应报表',
  'p.tier4home.uploadimg': '上传图片',
  'p.tier4home.cover': '封面',
  'p.tier4home.inside': '内部',
  'p.tier4home.title': '标题',
  'p.tier4home.link': '链接',
  'p.tier4home.area': '区域',
  'p.tier4home.editingmsg': '正在编辑区域{area}',
  'p.tier4home.report': '报表',
  'p.tier4home.reportlinkEN': '报表地址（英文）',
  'p.tier4home.reportlinkZH': '报表地址（中文）',
  'p.tier4home.reportinterval': '刷新间隔',
  'p.tier4home.bottomArea': '底部区域',
  'p.tier4home.carouselPic': '走马灯',
  'p.tier4home.employeePic': '员工风采',
  'p.andonstatus.pagetitle': '配置 - Andon状态',
  'p.andonstatus.refreshinterval': '刷新间隔',
  'p.andonstatus.andonmap': 'Andon地图',
  'p.andonstatus.maptips':
    '按下鼠标左键拖拽选择区域，区域中心为andon位置；双击区域可设置对应生产线',
  'p.andonstatus.station': '工位',
};
export default messages;
