import error from './en-US/error';
import menu from './en-US/menu';
import pages from './en-US/pages';
import change from './en-US/change';
import tpm from './en-US/tpm';

const enUS = {
  'common.loading': 'Loading...',
  'common.search': 'Search',
  'common.clear': 'Clear',
  'common.add': 'Add',
  'common.edit': 'Edit',
  'common.delete': 'Delete',
  'common.save': 'Save',
  'common.cancel': 'Cancel',
  'common.submit': 'Submit',
  'common.reset': 'Reset',
  'common.close': 'Close',
  'common.delcfm': 'Are you sure to delete {value}?',
  'common.thisitem': 'this item',
  'common.deleting': 'Deleting...',
  'common.yes': 'Yes',
  'common.no': 'No',
  'common.unknown': 'Unknown',
  'common.nocontent': 'No Content',
  'common.loadmore': 'Load More',
  'common.success': 'Success',
  'common.finish': 'Finish',
  'common.confirm': 'Confirm',
  'common.fallback': 'Fallback',
  'common.reject': 'Reject',
  'common.access': 'Access',
  'common.transfer': 'Transfer',
  'common.withdrawal': 'Withdrawal',
  'common.back': 'Back',
  'common.handle': 'Handle',
  'common.detail': 'Detail',
  'common.keyword': 'Keyword',
  'common.attachment': 'Attachment',
  'common.exportexcel': 'Export Excel',
  'common.obsolete': 'Are you sure to obsolete {value}？',
  'common.example': 'e.g. {value}',
  'layout.appname': ' CN PDP Digital Management Platform',
  'layout.lang': 'Languages',
  'layout.signout': 'Sign Out',
  'layout.download': 'Downloads',
  ...error,
  ...menu,
  ...pages,
  ...change,
  ...tpm,
};
export default enUS;
