// 请求状态
export const REQUEST_STATUS = {
  IDLE: 'idle',
  PENDING: 'pending',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
};

// 通用action类型
// 其他自定义类型的值应使用小写（如'add_submenu'），避免与common types中的值重复
export const COMMON_TYPES = {
  GETLIST: 'GETLIST',
  GET: 'GETITEM',
  ADD: 'ADD',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  DELETE: 'DELETE',
  DISCARD: 'DISCARD',
  TABLECHANGE: 'TABLECHANGE',
};

export const REGX_GUID = /^[A-Fa-f0-9]{8}(-[A-Fa-f0-9]{4}){3}-[A-Fa-f0-9]{12}$/;

export const APP_IGARID = process.env.REACT_APP_IGARID;
// local storage keys
export const LSK_TIER4HOME_LANG = `${APP_IGARID}.tier4home.lang`
